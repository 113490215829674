import React from "react";
import "./nav.css";

const Nav = () => {
  return (
    <footer id="sticky-footer" class="flex-shrink-0 py-4 bg-dark text-white-50">
      <div class="container text-center">
        <small style={{color:"#fff"}}>Copyright &copy; Broadcom</small>
      </div>
    </footer>
  );
};

export default Nav;
