/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import "./login.css";
import Swal from "sweetalert2";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import loginmobile from "../Assets/mobile login.png";

const Login = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    firstname: "",
    mobile: "",
    Email: "",
    designation: "",
    company: "",
    location: "",
    tShirt: "",
    emergencyMobile: "",
    customAction: "",
    otp: "",
    image: "",
  });

  const [activeTab, setActiveTab] = useState(1);

  const toggletab = (tab) => {
    setActiveTab(tab);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleAction = (action) => {
    console.log(action);
    setUser({
      ...user,
      customAction: action,
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_ADMIN}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      const data = await response.json();
      // console.log("response: ", data.data);
      if (data.mssg === "OTP send successfully") {
        Swal.fire({
          title: "",
          text: `OTP sent on ${data.data.Email} successfully`,
          icon: "success",
        });
      }

      if (data.mssg === "User Verified") {
        setIsAuthenticated(true);

        const localStorageData = {
          _id: data?.data?._id,
          Email: data?.data?.Email,
        };
        localStorage.setItem("authUser", JSON.stringify(localStorageData));
      }

      if (data.mssg === "No user found!") {
        Swal.fire({
          title: "Error!",
          text: "No user found!",
          icon: "info",
        });
      }
      if (
        data.mssg ===
        "Entered OTP failed to match. Please try again with latest OTP."
      ) {
        Swal.fire({
          text: "Entered OTP failed to match. Please try again with latest OTP.",
          icon: "error",
        });
      }

      if (data.mssg === "Email ID not Registered") {
        Swal.fire({
          title: "",
          text: `Email Id not Registered`,
          icon: "info",
        });
      }

      // setUser({ Email: "", otp: "", customAction: "" });
    } catch (error) {
      console.log("error submitting form", error);
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_ADMIN}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      const data = await response.json();
      // console.log("response: ", data);

      if (data.mssg === "registration sucess") {
        Swal.fire({
          title: "",
          text: `Thank You for Registering`,
          icon: "success",
        });
        setActiveTab(2);
      }

      if (data.mssg === "Internal Server Error") {
        Swal.fire({
          title: "",
          text: `Failed to Register`,
          icon: "error",
        });
      }

      setUser({
        firstname: "",
        mobile: "",
        Email: "",
        designation: "",
        company: "",
        location: "",
        fovtId: "",
        tShirt: "",
        emergencyMobile: "",
        customAction: "",
        otp: "",
      });
    } catch (error) {
      console.log("error submitting form", error);
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();

    if (!user.Email) {
      alert("Please enter Email");
      return;
    }

    const image = e.target.files[0];
    const maxSize = 5 * 1024 * 1024;
    if (image.size > maxSize) {
      alert("Image size should be less than 5MB");
      return;
    }

    try {
      const resp = await uploadImage(image);

      setUser((prevUser) => ({
        ...prevUser,
        image: resp || "",
      }));
    } catch (error) {
      console.error("Error uploading image: ", error);
      alert("There was an error uploading the image. Please try again.");
    }
  };

  return (
    <div id="login">
      {isMobile ? (
        <img
          className="loginimage"
          width="100%"
          // src={loginmobile}
          src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FPhone%2Fmobile%20login.png?alt=media"
          style={{ position: "absolute" }}
        />
      ) : (
        <img
          className="loginimage"
          width="100%"
          // src={Logimg}
          src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FPhone%2FNew%20reg%20Page%20laptop.png?alt=media"
          style={{ position: "absolute" }}
        />
      )}

      <div className="">
        <div
          id="login-row"
          className="row justify-content-center align-items-center"
        >
          <div id="login-column" className="col-md-6">
            <div id="login-box" className="col-md-12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  margin: "5% 0",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#cc0a2f",
                    fontWeight: "600",
                    borderRadius: "0.3rem 0 0 0.3rem",
                    backgroundColor: activeTab === 1 ? "#fff" : "transparent",
                    color: activeTab === 1 ? "#000" : "#fff",
                  }}
                  className="text-center active-tab poppins"
                  onClick={() => {
                    toggletab(1);
                  }}
                >
                  Register
                </div>
                <div
                  style={{
                    color: "#cc0a2f",
                    fontWeight: "600",
                    borderRadius: "0 0.3rem 0.3rem 0",
                    backgroundColor: activeTab === 2 ? "#fff" : "transparent",
                    color: activeTab === 2 ? "#000" : "#fff",
                  }}
                  className="text-center active-tab poppins"
                  onClick={() => {
                    toggletab(2);
                  }}
                >
                  Login
                </div>
              </div>

              {activeTab == "1" ? (
                <>
                  {/* register form */}
                  <form
                    id="register-form"
                    className="form"
                    onSubmit={handleRegisterSubmit}
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group mb-2">
                          <input
                            required
                            type="text"
                            name="firstname"
                            placeholder="Full Name *"
                            className="form-control"
                            value={user.firstname}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <input
                            required
                            type="tel"
                            maxLength={10}
                            name="mobile"
                            placeholder="Phone Number *"
                            className="form-control"
                            value={user.mobile}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form-group mb-2">
                          <input
                            required
                            type="email"
                            name="Email"
                            placeholder="Email Id *"
                            className="form-control"
                            value={user.Email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <input
                            required
                            type="text"
                            name="designation"
                            placeholder="Designation *"
                            className="form-control"
                            value={user.designation}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group mb-2">
                          <input
                            required
                            type="text"
                            name="company"
                            placeholder="Company Name *"
                            className="form-control"
                            value={user.company}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <input
                            required
                            type="text"
                            name="location"
                            placeholder="Location *"
                            className="form-control"
                            value={user.location}
                            onChange={handleChange}
                          />
                        </div>
                        {/* <div className="form-group mb-2">
                          <input
                            required
                            type="text"
                            name="govtId"
                            placeholder="Govt Id for pre check-In *"
                            className="form-control"
                            value={user.govtId}
                            onChange={handleChange}
                          />
                        </div> */}

                        <div className="form-group mb-2">
                          <input
                            type="tel"
                            maxLength={10}
                            name="emergencyMobile"
                            placeholder="Emergency Contact Number *"
                            className="form-control"
                            value={user.emergencyMobile}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-group mb-2">
                          <select
                            className="form-control"
                            name="tShirt"
                            value={user.tShirt}
                            onChange={handleChange}
                            required
                          >
                            <option disabled value="">
                              Select T-Shirt size
                            </option>
                            <option value="S">
                              S-38  (Neck-14.8cms | Length-70cms | Chest-97cms)
                            </option>
                            <option value="M">
                              M-40 (Neck-15.3cms | Length-72cms | Chest-1.05MTR)
                            </option>
                            <option value="L">
                              L-42 (Neck-15.8cms | Length-74cms | Chest-1.13MTR)
                            </option>
                            <option value="XL">
                              XL-44 (Neck-16cms | Length-75cms | Chest-1.18MTR)
                            </option>

                            <option value="XXL">
                              XXL-46 &nbsp;(Neck-16.3cms | Length-76cms | Chest-1.23MTR)
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-2 col-lg-12 col-md-12 col-sm-12">
                      <label
                        className="poppins"
                        style={{ color: "#fff", marginBottom: "0" }}
                      >
                        Upload your passport size photograph
                      </label>
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        name="image"
                        placeholder="Upload Image (Less than 5mb)*"
                        className="form-control"
                        onChange={handleImageUpload}
                      />
                      <p
                        className="poppins"
                        style={{ fontSize: "10px", color: "#fff" }}
                      >
                        Images should be less than 5MB*
                      </p>
                    </div>
                    {/* <div className="form-group mb-2 col-lg-12 col-md-12 col-sm-12">
                      <input
                        type="tel"
                        maxLength={10}
                        name="emergencyMobile"
                        placeholder="Emergency Contact Number *"
                        className="form-control"
                        value={user.emergencyMobile}
                        onChange={handleChange}
                        required
                      />
                    </div> */}

                    <br />
                    <div className="form-group submit-btns">
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "#cc0a2f",
                          color: "#fff",
                          outline: "1px solid #fff",
                          padding: "1% 8%",
                        }}
                        className="btn btn-md"
                        name="register"
                        onClick={() => handleAction("register")}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  {/* login form */}
                  <form
                    id="login-form"
                    className="form"
                    onSubmit={handleLoginSubmit}
                  >
                    <div className="form-group mb-2">
                      <input
                        type="email"
                        name="Email"
                        placeholder="Email ID *"
                        className="form-control"
                        value={user.Email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-2">
                      <input
                        type="text"
                        name="otp"
                        placeholder="OTP shared on Mail"
                        className="form-control"
                        value={user.otp}
                        onChange={handleChange}
                      />
                    </div>
                    <br />
                    <div className="form-group submit-btns">
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "#cc0a2f",
                          color: "#fff",
                          outline: "1px solid #fff",
                        }}
                        className="btn btn-md"
                        name="generateOTP"
                        onClick={() => handleAction("generate")}
                      >
                        Generate OTP
                      </button>
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "#cc0a2f",
                          color: "#fff",
                          outline: "1px solid #fff",
                        }}
                        className="btn btn-md"
                        name="verifyOTP"
                        onClick={() => handleAction("verify")}
                      >
                        Verify OTP
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
