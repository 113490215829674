import React from "react";
import "./hero.css";

const Hero = () => {
  return (
    <div id="hero" className="hero-container">
      <img
        className="hero-icon" loading="lazy" width="50%"
        src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2Fcharge%20the%20change%20white%20200x200.png?alt=media"
        alt="Background"
      />
        <div class="overview-overlay-2"></div>

      {/* <div className="hero-content first">
        <h5 className="gradient-underline">Charge The Change 2024</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in.
        </p>
      </div> */}
      {/* <div className="hero-content second">
        <h5 className="gradient-underline">Charge The Change 2024</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in.
        </p>
      </div> */}
    </div>
  );
};

export default Hero;
