import React from 'react'
import Nav from './nav'
import Hero from './hero'
import Overview from './overview'
import Foot from './foot'

const Home = () => {
  return (
    <>
      <Nav/>
      <Hero/>
      <Overview/>
      <Foot/>
    </>
  )
}

export default Home