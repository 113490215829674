import React from "react";
import "./nav.css";
import logo from "../Assets/logo.png"
import { Link } from 'react-scroll';


const Nav = () => {
  return (
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <a class="navbar-brand" href="#hero">
          <img
            width="100%"
            src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2Flogo.png?alt=media"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          style={{ justifyContent: "center" }}
          class="collapse navbar-collapse"
          id="navbarNav"
        >
          <ul
            class="navbar-nav"
            style={{ justifyContent: "center", width: "100%" }}
          >
            <li class="nav-item" style={{ marginRight: "5%" }}>
            <Link  to="overview" smooth={true} duration={500}><span  style={{ color: "#000", fontWeight: "600" }} className="nav-link">Overview</span></Link>
            </li>
           
            <li class="nav-item" style={{ marginRight: "5%" }}>
              <Link  to="speakers" smooth={true} duration={500}><span  style={{ color: "#000", fontWeight: "600" }} className="nav-link">Speakers</span></Link>
            </li>
            <li class="nav-item" style={{ marginRight: "5%" }}>
            <Link  to="agenda" smooth={true} duration={500}><span  style={{ color: "#000", fontWeight: "600" }} className="nav-link">Agenda</span></Link>
            </li>
            <li class="nav-item">
            <Link  to="contact" smooth={true} duration={500}><span  style={{ color: "#000", fontWeight: "600" }} className="nav-link">Help & Support</span></Link>
            </li>
          </ul>
        </div>
        <a class="navbar-brand" href="#hero">
          <img
            width="100%"
            src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2Fcharge%20the%20change%20white.png?alt=media"
          />
        </a>
        <div className="logout" style={{display:"flex",cursor:"pointer",justifyContent:"center",alignItems:"center"}} onClick={()=>{
          localStorage.removeItem("authUser");
window.location.reload();
}}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="#cc0a2f"
            class="bi bi-box-arrow-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
            />
            <path
              fill-rule="evenodd"
              d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
            />
          </svg>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
