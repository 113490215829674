import React from "react";
import "./hero.css";
import agendaOutline from "../Assets/agenda-outline.png";
import udaipur from "../Assets/udaipur.png";
import accelerate from "../Assets/Acceletate Innovation.png";
import strategic from "../Assets/Drive Strategic Alignment.png";
import strength from "../Assets/Strengthen Collaboration.png";
import logo from "../Assets/Charge the Change logo 500x150.png"
import AgendaLogo from "../Assets/Agenda-04.png"

import pradeepnair from "../Assets/pradeep.png";
import nicolecadet from "../Assets/Nicole Cadet VMWare Photo.jpg";
import taywenbin from "../Assets/2015_TayWenBin_02.jpg";
import murugan from "../Assets/murugan.png";
import raghu from "../Assets/Raghu 6.jpg";


const Overview = () => {
  return (
    <>
      {/* overview */}
      <div className="overview">
        <div className="logo" style={{ display: "none" }}>
          <img width="90%"
            className="mb-5"
            src={logo}
          />
        </div>
        <h3
          style={{ position: "relative", zIndex: "2", color: "black" }}
          className="overview-header mb-2 text-danger"
        >
          India Partner Leadership Summit
        </h3>
        {/* <div className="overview-left">
          <h3 className="m-5 text-light"></h3>
        </div> */}

        <div
          style={{
            position: "relative",
            zIndex: "2",
            color: "black",
            fontWeight: "500",
            textAlign: "center",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="col-md-8">
            <div class="">
              <p className="overview-text" style={{ textAlign: "left", fontSize: "1.5rem" }}>
                In today's fast-paced technological landscape, successful
                transformation demands strong partnerships and visionary
                leadership.
              </p>
              <p className="overview-text" style={{ textAlign: "left", fontSize: "1.5rem" }}>
                The{" "}
                <span className="text-danger">
                  Charge the Change: India Partner Leadership Summit with Broadcom</span>{" "}
                is designed to:
              </p>
              {/* <ul style={{ textAlign: "left" }}>
                <li>Accelerate Innovation</li>
                <li>Strengthen Collaboration</li>
                <li>Drive Strategic Alignment</li>
              </ul> */}
              <div className="overview-card pb-4">
                <div className="speaker-cards">
                  <div
                    className="card"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="icon p-4">
                      <img
                        style={{ filter: "none" }}
                        width="70%"
                        src={accelerate}
                      />
                    </div>
                    <div className="info">
                      <p className="title">
                        <span className="textcolor">Accelerate Innovation</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="speaker-cards">
                  <div
                    className="card"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="icon p-4">
                      <img
                        style={{ filter: "none" }}
                        width="70%"
                        src={strategic}
                      />
                    </div>
                    <div className="info">
                      <p className="title">
                        <span className="textcolor">
                          Strengthen Collaboration
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="speaker-cards">
                  <div
                    className="card"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="icon p-4">
                      <img
                        style={{ filter: "none" }}
                        width="70%"
                        src={strength}
                      />
                    </div>
                    <div className="info">
                      <p className="title">
                        <span className="textcolor">
                          Drive Strategic Alignment
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="overview-card">
<div className="ovreview-card-image-top" style={{position:"absolute"}}>
  <img width="40%" src={accelerate}/>
</div>
              </div> */}

              <p className="overview-text" style={{ textAlign: "left", fontSize: "1.5rem" }}>
                This summit represents our shared commitment to not only adapt
                to changes but to lead them.
              </p>
            </div>
          </div>
          <div>
            {/* <div>
              <img
                style={{ boxShadow: "red 15.95px -18.05px 0" }}
                src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Advantage%202024%2FOverview.png?alt=media"
              />
            </div> */}
          </div>
        </div>
      </div>

      {/* lets relax and recharge */}
      <div className="relax" style={{ position: "relative" }}>
        <div className="relax-container">
          <div className="relax-header-text">
            <h1 className="text-danger pt-5" style={{ fontSize: "4rem" }}>
              Event Objectives
            </h1>
          </div>

          <div className="relax-bottom">
            <div className="relax-content-container">
              {/* <ul style={{ lineHeight: "4rem", fontSize: "1.5rem" }}>
                <li className="ml-5">
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "2rem",
                      fontWeight: "800",
                      letterSpacing: "3px",
                    }}
                  >
                    Reflect
                  </span>{" "}
                  on our past achievements and lessons learned, setting a strong
                  foundation for future endeavors.
                </li>
                <li>
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "2rem",
                      fontWeight: "800",
                      letterSpacing: "3px",
                    }}
                  >
                    {" "}
                    Rejoice
                  </span>{" "}
                  in our collaborative successes and strengthen the bonds
                  between our teams.
                </li>
                <li className="ml-5">
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "2rem",
                      fontWeight: "800",
                      letterSpacing: "3px",
                    }}
                  >
                    {" "}
                    Reward
                  </span>{" "}
                  the outstanding contributions of our partners and team members
                  who have driven our joint initiatives forward.
                </li>
                <li>
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "2rem",
                      fontWeight: "800",
                      letterSpacing: "3px",
                    }}
                  >
                    Recharge
                  </span>{" "}
                  our partnership by sharing insights, aligning strategies, and
                  exploring new opportunities for innovation.
                </li>
                <li className="ml-5">
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "2rem",
                      fontWeight: "800",
                      letterSpacing: "3px",
                    }}
                  >
                    Relax
                  </span>{" "}
                  and build personal connections in a serene and inspiring
                  environment.
                </li>
              </ul> */}

              <div class="ag-format-container">
                <div class="ag-courses_box">
                  <div class="ag-courses_item">
                    <a class="ag-courses-item_link">
                      <div class="ag-courses-item_bg"></div>


                      <div class="ag-courses-item_title">
                        <span
                          className="ag-courses-item-title-text"
                          style={{
                            color: "#000",
                            fontSize: "2rem",
                            fontWeight: "800",
                            letterSpacing: "3px",
                          }}
                        >
                          Reflect
                        </span>
                      </div>

                      <div class="ag-courses-item_date-box">
                        on our past achievements and lessons learned, setting a
                        strong foundation for future endeavors.
                      </div>
                    </a>
                  </div>

                  <div className="ag-courses_item">
                    <a className="ag-courses-item_link">
                      <div className="ag-courses-item_bg"></div>
                      <div className="ag-courses-item_title">
                        <span
                          className="ag-courses-item-title-text"
                          style={{
                            color: "#000",
                            fontSize: "2rem",
                            fontWeight: "800",
                            letterSpacing: "3px",
                          }}
                        >
                          Rejoice
                        </span>
                      </div>
                      <div className="ag-courses-item_date-box">
                        in our collaborative successes and strengthen the bonds
                        between our teams.
                      </div>
                    </a>
                  </div>

                  <div class="ag-courses_item">
                    <a class="ag-courses-item_link">
                      <div class="ag-courses-item_bg"></div>

                      <div class="ag-courses-item_title">
                        <span
                          className="ag-courses-item-title-text"
                          style={{
                            color: "#000",
                            fontSize: "2rem",
                            fontWeight: "800",
                            letterSpacing: "3px",
                          }}
                        >
                          Reward
                        </span>
                      </div>

                      <div class="ag-courses-item_date-box">
                        the outstanding contributions of our partners and team
                        members who have driven our joint initiatives forward.
                      </div>
                    </a>
                  </div>

                  <div class="ag-courses_item">
                    <a class="ag-courses-item_link">
                      <div class="ag-courses-item_bg"></div>

                      <div class="ag-courses-item_title">
                        <span
                          className="ag-courses-item-title-text"
                          style={{
                            color: "#000",
                            fontSize: "2rem",
                            fontWeight: "800",
                            letterSpacing: "3px",
                          }}
                        >
                          Recharge
                        </span>
                      </div>

                      <div class="ag-courses-item_date-box">
                        our partnership by sharing insights, aligning
                        strategies, and exploring new opportunities for
                        innovation.
                      </div>
                    </a>
                  </div>

                  <div class="ag-courses_item">
                    <a class="ag-courses-item_link">
                      <div class="ag-courses-item_bg"></div>

                      <div class="ag-courses-item_title">
                        <span
                          className="ag-courses-item-title-text"
                          style={{
                            color: "#000",
                            fontSize: "2rem",
                            fontWeight: "800",
                            letterSpacing: "3px",
                          }}
                        >
                          Relax
                        </span>
                      </div>

                      <div class="ag-courses-item_date-box">
                        and build personal connections in a serene and inspiring
                        environment.
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="overview-overlay-4"></div> */}
      </div>

      {/* speakers */}
      <div
        id="speakers"
        className="speakers pb-5"
        style={{
          // display: "none",
          position: "relative",
        }}
      >
        {/* <div className="overview-overlay-5"></div> */}

        <h1 className="mb-5 pt-5 text-center text-black">
          Here are our
          <br />
          <span className="text-danger" style={{ fontSize: "4rem" }}>
            Impactful Speakers
          </span>{" "}
          for this year
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            // columnGap: "8%",
            columnGap: "13%",
          }}
        >
        <div className="speaker-cards"style={{borderTopRightRadius: "45px"}}>
            <div className="card"style={{borderTopRightRadius: "45px"}}>
              <div className="icon" style={{
                backgroundColor: "#cc0a2f",
                borderTopRightRadius: "45px",

              }}>
                <img
                  width="100%"
                  src={pradeepnair}
                />
              </div>
              <div className="info">
                <p className="title">
                  <span className="textcolor">Pradeep</span> Nair
                </p>
                <p className="designation">Managing Director</p>
                <p className="text">VMware by Broadcom - India</p>
              </div>
            </div>
          </div>
          <div className="speaker-cards"style={{borderTopRightRadius: "45px"}}>
            <div className="card"style={{borderTopRightRadius: "45px"}}>
              <div className="icon" style={{
                backgroundColor: "#cc0a2f",
                borderTopRightRadius: "45px",

              }}>
                <img
                  width="100%"
                  src={murugan}
                />
              </div>
              <div className="info">
                <p className="title">
                  <span className="textcolor">Murugon</span> Enom
                </p>
                <p className="designation">Head of Solution <br /> Engineering</p>
                <p className="text">VMware by Broadcom - India</p>
              </div>
            </div>
          </div>

          <div className="speaker-cards"style={{borderTopRightRadius: "45px"}}>
            <div className="card"style={{borderTopRightRadius: "45px"}}>
              <div className="icon" style={{
                backgroundColor: "#cc0a2f",
                borderTopRightRadius: "45px",

              }}>
                <img
                  width="100%"
                  src={nicolecadet}
                />
              </div>
              <div className="info">
                <p className="title">
                  <span className="textcolor">Nicole</span> Cadet
                </p>
                <p className="designation">Senior Director</p>
                <p className="text">Commercial,<br /> Asia Pacific & Japan, Vmware</p>
              </div>
            </div>
          </div>





          <div className="speaker-cards"style={{borderTopRightRadius: "45px"}}>
            <div className="card"style={{borderTopRightRadius: "45px"}}>
              <div className="icon" style={{
                backgroundColor: "#cc0a2f",
                borderTopRightRadius: "45px",

              }}>
                <img
                  width="100%"
                  src={raghu}
                />
              </div>



              <div className="info">
                <p className="title">
                  <span className="textcolor">Raghuram </span> Krishan
                </p>
                <p className="designation">Director</p>
                <p className="text">India Partner &  Commercial Sales</p>
              </div>
            </div>
          </div>
          <div className="speaker-cards"style={{borderTopRightRadius: "45px"}}>
            <div className="card"style={{borderTopRightRadius: "45px"}}>
              <div className="icon" style={{
                backgroundColor: "#cc0a2f",
                borderTopRightRadius: "45px",

              }}>
                <img
                  width="100%"
                  src={taywenbin}
                />
              </div>
              <div className="info">
                <p className="title">
                  <span className="textcolor">Tay Wen</span> Bin
                </p>
                <p className="designation">Head of Commercial</p>
                <p className="text">Partner Solution Architects, <br />Asia Pacific (APAC)</p>
              </div>
            </div>
          </div>

          {/* <div className="speaker-cards">
            <div className="card">
              <div className="icon">
                <img
                  width="100%"
                  src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Advantage%202024%2Fspeaker.png?alt=media&token=79b5dd79-3e35-4043-88dc-4cc884a7d227"
                />
              </div>
              <div className="info">
                <p className="title">
                  <span className="textcolor">Speaker</span> Name
                </p>
                <p className="designation">Designation</p>
                <p className="text">Organization</p>
              </div>
            </div>
          </div>
          <div className="speaker-cards">
            <div className="card">
              <div className="icon">
                <img
                  width="100%"
                  src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Advantage%202024%2Fspeaker.png?alt=media&token=79b5dd79-3e35-4043-88dc-4cc884a7d227"
                />
              </div>
              <div className="info">
                <p className="title">
                  <span className="textcolor">Speaker</span> Name
                </p>
                <p className="designation">Designation</p>
                <p className="text">Organization</p>
              </div>
            </div>
          </div>
          <div className="speaker-cards">
            <div className="card">
              <div className="icon">
                <img
                  width="100%"
                  src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Advantage%202024%2Fspeaker.png?alt=media&token=79b5dd79-3e35-4043-88dc-4cc884a7d227"
                />
              </div>
              <div className="info">
                <p className="title">
                  <span className="textcolor">Speaker</span> Name
                </p>
                <p className="designation">Designation</p>
                <p className="text">Organization</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* Agenda */}
      <div className="agenda-bg-img">
        <img width="100%" src={AgendaLogo} alt="Agenda" class="agendalogo-mob" style={{ display: "none" }} />

        <div id="agenda" class="agendacontainer">
          <div class="agendarow agenda">
            <div class="column agenda-col col-4" style={{ borderRight: "2px solid #cc0a2f" }}>
              <h2>DAY 1</h2>
              <h3 className="text-danger border-bottom">
                7<sup>th</sup> AUG
              </h3>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  13:00 to 15:00
                </span>
                <br />
                Check-In
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  15:00 to 15:15
                </span>
                <br />
                Welcome Address
                {/* <br /> */}
                <span className="agenda-speakers">Raghuram Krishnan</span>
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  15:15 to 15:45
                </span>
                <br />
                Key-Note Address
                {/* <br /> */}
                <span className="agenda-speakers">Cynthia Lloyd</span>
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  15:45 to 16:30
                </span>
                <br />
                VMWare by Broadcom – Strategy & Way Forward

                {/* <br /> */}
                <span className="agenda-speakers">Pradeep Nair</span>
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  16:30 to 16:45
                </span>
                <br />
                Tea Break
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  16:45 to 17:30
                </span>
                <br />
                Partner Panel Discussion
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  17:30 to 18:30
                </span>
                <br />
                Break
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">18:30</span>
                <br />
                Assemble at Lobby
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  18:30 to 19:00
                </span>
                <br />
                Towards Welcome Night Dinner Venue
              </p>

              <p className="mb-3">
                <span className="text-danger agenda-timing">19:00 Onwards</span>
                <br />
                Welcome Dinner & Networking
              </p>
              <img
                width="70%"
                src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FAgenda%201.png?alt=media"
              />
            </div>
            <div class="column agenda-col col-4" style={{ borderRight: "2px solid #cc0a2f" }}>
              <h2>DAY 2</h2>
              <h3 className="text-danger border-bottom">
                8<sup>th</sup> AUG
              </h3>
              <img
                width="70%"
                src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FAgenda%202.png?alt=media"
              />
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  07:00 to 08:45
                </span>
                <br />
                Breakfast
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">08:45 to 09:00</span>
                <br />
                Recap of Day 1

              </p>
              {/* <p className="mb-3">
                <span className="text-danger agenda-timing">8:55 to 09:45</span>
                <br />
                Motivational Speech – Guest Speaker
              </p> */}
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  09:00 to 10:00
                </span>
                <br />
                VMWare VCF Portfolio - Positioning & Use Cases

              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  10:00 to 10:30
                </span>
                <br />
                Tea Break
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  10:30 to 11:15
                </span>
                <br />
                Empowering Partners
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  11:15 to 13:00
                </span>
                <br />
                CSR Activity

              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  13:00 to 14:00
                </span>
                <br />
                Lunch Break
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  14:00 to 14:45
                </span>
                <br />
                VMWare Private AI

              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  14:45 to 15:00
                </span>
                <br />
                Towards Breakout Sessions

              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  15:00 to 16:00
                </span>
                <br />
                Breakout Sessions

              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  16:00 to 16:15
                </span>
                <br />
                Towards Main Conference

              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  16:15 to 16:45
                </span>
                <br />
                Broadcom Channel Policy & Closing Note
              </p>
              {/* <p className="mb-3">
                <span className="text-danger agenda-timing">16:45 to 18:45</span>
                <br />
                Fresh & Change
              </p> */}
              <p className="mb-3">
                <span className="text-danger agenda-timing">19:00</span>
                <br />
                Partners Awards Night followed by Gala Dinner
              </p>
            </div>
            <div class="column agenda-col col-4">
              <h2>DAY 3</h2>
              <h3 className="text-danger border-bottom ">
                9<sup>th</sup> AUG
              </h3>
              <p className="mb-3">
                <span className="text-danger agenda-timing">
                  07:00 to 08:45
                </span>
                <br />
                Breakfast
              </p>
              <p className="mb-3">
                <span className="text-danger agenda-timing">10:00</span>
                <br />
                Check out & Transfer to Airport
              </p>
              <img
                width="70%"
                src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FAgenda%203.png?alt=media"
              />
            </div>
          </div>
        </div>
      </div>

      {/* bengaluru section */}
      <div className="bengaluru-section">
        <div className="overview-overla-7"></div>
        <div className="text-center ">
          <h1 className="outline-text">UDAIPUR</h1>
        </div>

        <div className="bengaluru-section-text">
          <div style={{ textAlign: "center", padding: "5%" }}>
            Udaipur, with its majestic palaces and tranquil lakes, provides an
            ideal setting for reflection and strategic planning. Its rich
            cultural heritage will inspire creativity and innovation, while its
            serene environment will foster meaningful interactions and
            collaboration.
          </div>
        </div>
      </div>

      {/* Harness section */}
      <div className="harness pb-5">
        {/* <div className="overview-overlay-6"></div> */}

        <div className="harness-container">
          <div className="row">
            <div className="col">
              <h1
                className="text-danger harness-header"
                style={{
                  fontSize: "3rem",
                  letterSpacing: "2",
                  fontWeight: "900",
                }}
              >
                Charge the Change and Follow these Guidelines
              </h1>
              {/* <h3 className="mb-3" style={{ fontWeight: "700", color: "#000" }}>
                AND FOLLOW THESE GUIDELINES
              </h3> */}

              <h6
                className="mb-3 harness-subtext"
                style={{ fontSize: "1.5rem", color: "#000" }}
              >
                As we are gearing up for
              </h6>
              <div className="harness-faq" style={{ color: "#000" }}>
                <h3 className="mb-3 harness-subtext">Here's what you need to know!</h3>
                <ul>
                  <li className="mb-3" style={{ fontSize: "1.5rem" }}>
                    <span style={{ fontWeight: "900" }} className="text-danger">
                      Weather Forecast:
                    </span>{" "}
                    In Udaipur during the summit, max 34°C and min 23°C.
                  </li>
                  <li className="mb-3" style={{ fontSize: "1.5rem" }}>
                    <span style={{ fontWeight: "900" }} className="text-danger">
                      ID Cards:
                    </span>{" "}
                    Carry your Govt IDs for hotel check-ins and airport entry.
                  </li>
                  <li className="mb-3" style={{ fontSize: "1.5rem" }}>
                    <span style={{ fontWeight: "900" }} className="text-danger">
                      Flight Check-in:{" "}
                    </span>
                    Do web check-in for your flights in advance to avoid any
                    last-minute rush.
                  </li>
                  <li className="mb-3" style={{ fontSize: "1.5rem" }}>
                    <span style={{ fontWeight: "900" }} className="text-danger">
                      Transport:{" "}
                    </span>
                    Airport Transfers will be provided at the Udaipur airport
                    for transport to and from the offsite venue.
                  </li>
                  <li className="mb-3" style={{ fontSize: "1.5rem" }}>
                    <span style={{ fontWeight: "900" }} className="text-danger">
                      Room Allocations:
                    </span>{" "}
                    Please do not request changes in allocated rooms.
                  </li>
                  <li className="mb-3" style={{ fontSize: "1.5rem" }}>
                    <span style={{ fontWeight: "900" }} className="text-danger">
                      Timeliness:
                    </span>{" "}
                    Kindly adhere to the timelines provided in the agenda and
                    try to be on time for the activities.
                  </li>
                  <li className="mb-3" style={{ fontSize: "1.5rem" }}>
                    <span style={{ fontWeight: "900" }} className="text-danger">
                      Emergency Contacts:
                    </span>{" "}
                    Update your emergency contact numbers on the registration
                    portal and carry any necessary medication with you.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div
                className="harness-container-right-outer text-center text-white"
                style={{
                  border: "2px solid red",
                  borderRadius: "20px",
                  marginTop: "40%",
                }}
              >
                <h1 className="mb-5 text-dark ">Dress Code</h1>
                <h4 className="text-dark">Welcome Dinner</h4>
                <h3 className="mb-5 text-danger">Indian Ethnic
                </h3>

                <h4 className="text-dark">Conference</h4>
                <h3 className="text-danger">Business Casuals
                </h3>
                  <h4 className=" mb-5 text-dark">(Company T-shirt will be provided)</h4>
                
                 
              

                <h4 className="text-dark">Gala Awards Night</h4>
                <h3 className="text-danger">Black Tie Dinner</h3>

                <h4 className="mb-5" style={{ color: "" }}>
                  Theme Oscars
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* contact us */}

      <div id="contact" className="contact">
        <div className="contact-container">
          <div className="text-center" style={{ marginBottom: "10%" }}>
            <img
              className="mb-5"
              src={logo}
            />
            <h2
              className="text-danger text-center mb-3"
              style={{ fontSize: "4rem" }}
            >
              Together Towards Triumph
            </h2>
            <div>
              <p style={{ fontSize: "1.5rem" }}>
                This summit is more than just an event; it's a pivotal moment in
                our journey together. By charging the change, we commit to
                leading the future of our industries through innovation,
                collaboration, and strategic alignment. Let’s march ahead,
                united in our vision and determined in our pursuit of
                excellence.
              </p>
            </div>
          </div>
          <h2 className="text-center  text-danger" style={{ fontSize: "4rem" }}>
            Contact Us
          </h2>

          {/* <h3 className="text-center">
            For any talent show related queries please contact{" "}
          </h3>
          <a
            href="mailto:marketing@sttelemediagdc.in"
            className="text-center mb-5"
          >
            marketing@sttelemediagdc.in
          </a> */}
          {/* <h3 className="text-center">
            For any logistics related queries please contact{" "}
          </h3> */}

          <h3 className="text-center" style={{ fontSize: "2rem" }}>
            For any queries please contact
          </h3>
          <h3
            className="text-center font-weight-bold"
            style={{ fontSize: "1.5rem" }}
          >
            Shivani Sharma
          </h3>
          <a
            href="mailto:neha.garg@wizard-events.in"
            className="text-center mb-2"
          >
            shivani.sharma@wizard-events.in
          </a>
          <a href="tel:9999862331">99998 62331</a>
        </div>
        <div className="contactsvg" style={{ position: "relative" }}>
          <svg
            style={{ position: "absolute", bottom: "-2px", zIndex: "-1" }}
            viewBox="0 0 1440 161"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 128.288L48 112.288C96 96.2882 192 64.2882 288 37.5882C384 11.2882 480 -10.7118 576 5.58817C672 21.2882 768 75.2882 864 90.9882C960 107.288 1056 85.2882 1152 90.9882C1248 96.2882 1344 128.288 1392 144.288L1440 160.288H1392C1344 160.288 1248 160.288 1152 160.288C1056 160.288 960 160.288 864 160.288C768 160.288 672 160.288 576 160.288C480 160.288 384 160.288 288 160.288C192 160.288 96 160.288 48 160.288H0V128.288Z"
              fill="#CC0A2F"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Overview;
